.store-address-section{
    background-color: #EEEEEE;
    border-radius: 8px;
    font-size: .9rem;
}
.store-address-section .store-name,.store-address-section .store-address {
    font-size: 1.1rem;
    font-weight: bold;
}
.store-address-section{
    padding-top: 23px;
}
.store-address-section a {
    color: #EE1313;
}
.noPhone {
    color: #F98608;
}
.noWallet {
    color: #EE1313;
}
.noProblem{
    color: #000000;
}
.getDirections{
    margin-top: 10px;
    margin-bottom: 10px;
}
.distance {
    margin-top: 5px;
    padding-bottom: 30px;
}
.otp-error
 {
     color: #f80707;
 }