/** @format */

.navigation li {
	border-bottom: 1px solid #ffaf58;
}
.mobile-nav-item {
	text-decoration: none !important;
}

.mobile-nav-item {
	font-weight: 600;
	font-size: 1rem;
	text-transform: uppercase;
	color: #fff;
}

.mobile-nav-item:active,
.mobile-nav-item:focus,
.mobile-nav-item:visited {
	text-decoration: none;
	color: #fff;
}

/** @format */
.bm-burger-button {
	position: absolute;
	width: 30px;
	height: 20px;
	left: 15px;
	top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #fff;
	height: 8% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
	right: 8px;
	left: auto;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
	top: 0;
	position: fixed;
	height: 100%;
	left: 0;
}

/* General sidebar styles */
.bm-menu {
	background: #ff8500;
	padding: 3em 1.5em 0;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #fff;
}

/* Wrapper for item list */
.bm-item-list {
	color: #000;
}

/* Individual item */
.bm-item {
	display: inline-block;
	outline: none;
}

/* Styling of overlay */
.bm-overlay {
	top: 0;
	background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
	/*.bm-burger-button,*/
	/*.bm-menu-wrap,*/
	/*.bm-overlay {*/
	/*	display: none;*/
	/*}*/
	ul li button.mobile-nav-item {
		font-weight: 600;
		font-size: 1rem;
		text-transform: uppercase;
		color: #fff;
		text-decoration: underline;
		padding: 0;
		letter-spacing: 0;
		line-height: 0;
		vertical-align: baseline;
	}

	ul li button.mobile-nav-item:hover,a.mobile-nav-item:hover{
		color: #fff;
	}
	.navigation li {
		border: none;
	}
	.bm-burger-button,.bm-cross-button {
		display: none;
	}
	.bm-menu-wrap {
		position: relative !important;
		width: auto !important;
		height: auto !important;
		transform: none !important;
		transition: none !important;
	}
	.bm-menu {
		padding: 0;
		background: inherit;
	}
	ul.bm-item {
		display: flex !important;
		justify-content: center;
		align-content: center;
	}
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1200px) {
}
