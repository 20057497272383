/** @format */

.home-page {
	background-image: url("./img/bg-circles.svg");
	background-repeat: no-repeat;
	background-position: center -50vw;
	background-size: cover;
}

.home-page-heading {
	color: #fff;
	padding-top: 5rem;
}

.home-page-heading h1 {
	font-size: 3.6rem;
	font-weight: 700;
}

.home-page-heading p {
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
}

.home-page-heading h1 span {
	color: #fdd086;
}

.home-page-heading .hand-gif-label {
	font-weight: 300;
}

.home-page-signin {
	font-size: 1.2rem;
	font-weight: 400;
	padding: 1.2rem 3rem;
}

.home-gif {
	width: 100px;
	height: auto;
}
