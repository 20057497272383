.edit-billing-address .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.2rem + 2px) !important;
    padding: 0.3rem 0.75rem !important;
    font-size: 0.8rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.edit-billing-address .form-group label {
    margin-bottom: 2px;
    margin-top: 16px;
}