.pagination {
	position: relative;
}

/* .step-icon:after {
	content: "";
	position: absolute;
	height: 1px;
	background-color: #000;
	width: 50px;
	top: 14px;
	z-index: -1;
	right: -64px;
} */

.pagination-line {
	width: 75%;
	position: absolute;
	height: 1px;
	background: #ed1313;
	top: 32px;
	left: 0;
	right: 0;
	margin: auto;
}

.step-icon span {
	display: inline-block;
}
