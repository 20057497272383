/** @format */

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
	opacity: 0.5;
}

.modal-wrapper {
	position: fixed;
	top: 60px;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
}

.page-modal {
	z-index: 1000;
	background: white;
	position: absolute;
	left: 0;
	right: 0;
	top: 60px;
	margin: auto;
	display: block;
	width: 100%;
	height: 100%;
	padding: 2.5rem 1.5rem 1.5rem 1.5rem;
}

.page-modal .modal-body {
	height: 100% !important;
	overflow: scroll !important;
}

.page-modal-title {
	font-weight: 700;
}

.button-close-modal {
	position: absolute;
	z-index: 1000;
	left: 15px;
	top: 15px;
	line-height: 1;
	width: 15px;
	height: auto;
	padding: 0;
	background: transparent;
	border: none;
}

.button-close img {
	vertical-align: baseline;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
	.page-modal {
		width: 60%;
	}
}

@media (min-width: 1200px) {
	.page-modal {
		width: 500px;
	}
}

@media (min-width: 1600px) {
}

@media (min-width: 1800px) {
}
