/** @format */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
	/* overflow: hidden; */
	height: 100%;
}

.app-body {
	padding-top: 60px;
}

html,
body,
#root {
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Montserrat", sans-serif;
}

h1 {
	font-size: 1.5rem;
	font-weight: 700;
}

.btn {
	letter-spacing: 0.1rem;
}

.btn-sm {
	border-radius: 15px;
}

.btn-main {
	color: #fff;
	background-color: #ed1318;
	border-color: #ed1318;
	border-radius: 18px;
	text-transform: uppercase;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1;
	padding: 0.5rem 1rem;
}

.btn-main:active,
.btn-main:focus,
.btn-main:visited,
.btn-main:hover {
	color: #fff;
	background-color: #ed1318;
	border-color: #ed1318;
}

.btn-main-lg {
	color: #fff;
	border-radius: 34px;
	font-size: 1.2rem;
	font-weight: 600;
	padding: 1.2rem 3rem;
}

.btn-main-sm {
	border-radius: 18px;
	text-transform: uppercase;
	padding: 8px 12px;
	min-width: 100px;
	border-radius: 30px;
	font-size: 0.7rem;
	font-weight: 300;
	line-height: 1;
}

/* form fields */
input.form-control {
	border-radius: 0;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 1px solid #000;
	padding-left: 0rem;
	padding-right: 0rem;
	padding-left: -3.625rem 0rem;
	padding-left: -3.625rem 0rem;
}

input.form-control:focus {
	border-bottom: 2px solid #000;
}

.border-bottom-grey {
	border-bottom: 1px solid #909090;
}

.profile-title {
	font-weight: 700;
	font-size: 1rem;
}

.profile-data {
	color: #595959;
	font-weight: 300;
}

span.required {
	color: red;
	font-weight: bolder;
}
