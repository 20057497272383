.step-icon {
	border: 1px solid #ed1313;
	border-radius: 50%;
	position: relative;
	width: 32px;
	height: 32px;
	background: #fff;
}

.pagination-bullet .pagination-title {
	line-height: 1.2;
	font-size: 0.6rem;
	position: relative;
	display: block;
}

.pagination-bullet .pagination-title.active {
	font-weight: 800;
	color: #ed1313;
}

.pagination-bullet .pagination-title.complete {
	font-weight: 800;
	color: #000;
}

.step-icon .pagination-icon {
	line-height: 1;
	color: #ed1313;
}

.step-icon.complete {
	background: #ed1313;
}

.step-icon.complete .pagination-icon {
	color: #fff;
}
